<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">GGC Loan</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="selection"
              dense
              outlined
              label="Selections"
              :items="['Net Proceeds','Processing Fee','Loan Insurance Fee','NMAPI Fee','Legal & Doc Fee']"
              item-text="name"
              item-id="name"
              :rules="rules.combobox_rule"
              @change="selected_details"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="name"
              dense
              outlined
              label="Name of the Borrower"
              :items="name_items"
              item-text="name"
              item-id="name"
              :rules="rules.combobox_rule"
              @change="selected_name"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="total"
              label="Total"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      total: 0,
      total_raw: 0,
      less: 0,
      selection: '',
      computedd: 0,
      total_gross: 0,
      row: 'is_allowance',
      month_of: '',
      name: '',
      name_items: [],
      id: 0,
    }
  }
  export default {
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('request_data', ['check_under_particulars_availability', 'initialization_of_request_bod_allowance', 'released_bod_allowance']),
      ...mapActions('ggc_loans', ['list_of_pending_request_ggc_loan']),
      selected_details() {
        this.total = 0
        this.name_items = []
        const data = new FormData()
        data.append('status', this.selection);
        this.list_of_pending_request_ggc_loan(data)
          .then(response => {
            this.name_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.initialize_data()
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_name(value) {
        this.total_gross = ''
        this.month_of = ''
        this.less = 0
        this.total = 0
        this.total_raw = 0
        this.id = 0
        var index = this.name_items.map(function (x) {
          return x.name;
        }).indexOf(value)
        if (index != -1) {
          this.total_gross = this.selection === 'Net Proceeds' ? this.formatPrice(this.name_items[index].loan_amount) : 0
          this.total = this.selection === 'Net Proceeds' ? this.formatPrice(this.name_items[index].total_net) :
            (this.selection === 'Processing Fee' ? this.formatPrice(this.name_items[index].processing_fee) :
              (this.selection === 'Loan Insurance Fee' ? this.formatPrice(this.name_items[index].loan_insurance_fee) :
                (this.selection === 'NMAPI Fee' ? this.formatPrice(this.name_items[index].nmapi_fee) :
                  (this.selection === 'Legal & Doc Fee' ? this.formatPrice(this.name_items[index].legal_doc_fee) : 0))))

          this.total_raw = this.selection === 'Net Proceeds' ? this.name_items[index].total_net :
            (this.selection === 'Processing Fee' ? this.name_items[index].processing_fee :
              (this.selection === 'Loan Insurance Fee' ? this.name_items[index].loan_insurance_fee :
                (this.selection === 'NMAPI Fee' ? this.name_items[index].nmapi_fee :
                  (this.selection === 'Legal & Doc Fee' ? this.name_items[index].legal_doc_fee : 0))))
          this.id = this.name_items[index].id
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.row === 'is_allowance') {
            const data = new FormData()
            data.append('id', this.id);
            this.released_bod_allowance(data)
          }
          this.selection === 'Net Proceeds' ? this.$emit('data', 'ID-' + this.id + ' # Name-' + this.name + ' # ' + this.selection + ' # Gross-P' + this.total_gross + ' # Net-P' + this.total) :
            this.$emit('data', 'ID-' + this.id + ' # Name-' + this.name + ' # ' + this.selection + ' # P' + this.total)
          this.$emit('amount', this.total_raw)
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
