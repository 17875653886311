var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.title},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"mt-2",attrs:{"dense":"","headers":_vm.headers2,"items":_vm.list_of_request_pending,"hide-default-footer":"","disable-pagination":"","height":"450"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'info white--text': item.id === _vm.selectedDepositId },on:{"click":function($event){return _vm.activerow(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.compute_total(item.request_data))+" ")]),(item.is_approved === 0)?_c('td',[_vm._v(" "+_vm._s('(Created By: '+item.created_by.last_name+', '+ item.created_by.first_name+')')+" ")]):_vm._e(),(item.is_approved === 2 && item.approved_by!=null)?_c('td',[_c('v-chip',{attrs:{"color":"info","dark":""}},[_vm._v(" NEED FINAL APPROVAL ")]),_vm._v(" "+_vm._s('(Created By: '+item.created_by.last_name+', '+ item.created_by.first_name+') Approve By: '+item.approved_by.last_name+', '+ item.approved_by.first_name)+" ")],1):_vm._e(),(item.is_approved === 1 && item.approved_by!=null)?_c('td',[_vm._v(" "+_vm._s('(Created By: '+item.created_by.last_name+', '+ item.created_by.first_name+') Approve By: '+item.approved_by.last_name+', '+ item.approved_by.first_name)+" ")]):_vm._e(),(item.is_approved===0)?_c('td',{staticClass:"text-center"},[(!_vm.is_deleting_request && item.is_approved === 0&&(_vm.is_head === 1||(item.created_by.user_data[0].position==='EMPLOYEE'&&_vm.position!='EMPLOYEE')||
          (item.created_by.user_data[0].position === 'AMD' && _vm.employee_id===645)))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"},on:{"click":function($event){return _vm.change_status_pending_request(item.id, 0)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiThumbDown)+" ")]):_vm._e(),(_vm.is_deleting_request)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1):_vm._e(),_c('td',{staticClass:"text-center"},[(!_vm.is_deleting_request && item.is_approved === 0&&(_vm.is_head === 1||(item.created_by.user_data[0].position==='EMPLOYEE'&&_vm.position!='EMPLOYEE')||
          (item.created_by.user_data[0].position === 'AMD' && _vm.employee_id===645)))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.change_status_pending_request(item.id, 1)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiThumbUp)+" ")]):_vm._e(),(_vm.is_deleting_request)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1),_c('td',{staticClass:"text-center"},[(
                                    !_vm.is_deleting_request && item.created_id === _vm.employee_id && item.is_approved === 0
                                  )?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.delete_pending_request(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e(),(_vm.is_deleting_request)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1)])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.list_of_request_vouchered,"hide-default-footer":"","disable-pagination":"","height":"450"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                  var item = ref.item;
return [_c('tr',{class:{ 'info white--text': item.id === _vm.selectedDepositId },on:{"click":function($event){return _vm.activerow(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.category.category)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.compute_total(item.request_data))+" ")])])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.list_of_request_credited,"hide-default-footer":"","disable-pagination":"","height":"450"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                  var item = ref.item;
return [_c('tr',{class:{ 'info white--text': item.id === _vm.selectedDepositId },on:{"click":function($event){return _vm.activerow(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.category.category)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.compute_total(item.request_data))+" ")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }